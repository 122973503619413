<template>
     <CForm ref="form" class="needs-validation"   >
        <CInput
            label="Sayfa Adı:"
            type="text"
            autocomplete="text"
            v-model="data.title"
            horizontal
            required
        >
        </CInput>
        <CInput
            label="Özel Sayfa Adı:"
            horizontal
            type="text"
            autocomplete="text"
            v-model="data.uniqueID"
            required
        >
        </CInput>
        <CInput
            label="Sayfa Icon:"
            horizontal
            type="text"
            autocomplete="text"
            v-model="data.pageIcon"
        >
        </CInput>
        <CInput
            label="Açıklama:"
            horizontal
            type="text"
            autocomplete="text"
            v-model="data.description"
        >
        </CInput>
        <CInput
            horizontal
            type="number"
            label="Sıralama No:"
            v-model="data.displayOrder"
            required
        >
        </CInput>
        <CSelect
            horizontal
            label="Üst Sayfa"
            :value.sync="data.parentPageName"
            :options="parentPages"
        />
        <CRow form class="form-group">
            <CCol tag="label" sm="3" class="col-form-label">
                <b>Aktif</b>
            </CCol>
            <CCol>
                <CSwitch
                    class="mr-1"
                    size="lg"
                    color="primary"
                    :checked.sync="data.activeFlag"
                />
            </CCol>
        </CRow>
    </CForm>
</template>

<script>
    export default{
        name: "PageForm",
        props:{
            validated: Boolean,
            params: Object,
            actionType: String,
        },
        computed:{
            parentPages: function() {
                let data = []
                this.$store.getters.allParentPage.map(r => data.push({value:r.id, label: r.title}))
                return data
            },
        },
        watch:{
            params: function(val){
                this.data = {...val};
            },
            actionType:  function(val){
                if(val == 'create')
                    this.data = {}
            }
        },
        data: function(){
            return{
                data: this.params ? {...this.params} : {},
            }
        },
    }
</script>